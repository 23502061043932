import copySvg from './images/copy.svg';
import './App.css';
import arrowSvg from './images/arrow.svg';
import './i18n';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

function App() {
  const [selectedImage, setSelectedImage] = useState(0);
  const handleCopyIban = () => {
    setCopied(true);
    navigator.clipboard.writeText('HR9824070001500023652');

    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  const ref = useRef<HTMLDivElement>(null);
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState('hr');
  const [dropdown, setDropdown] = useState(false);
  const [copied, setCopied] = useState(false);

  const images = [
    'https://cdn.dotyourspot.com/images/donation-page/image_1.jpg',
    'https://cdn.dotyourspot.com/images/donation-page/image_2.jpg',
    'https://cdn.dotyourspot.com/images/donation-page/image_3.jpg',
    'https://cdn.dotyourspot.com/images/donation-page/image_4.jpg',
    'https://cdn.dotyourspot.com/images/donation-page/image_5.jpg',
    'https://cdn.dotyourspot.com/images/donation-page/image_6.jpeg',
    'https://cdn.dotyourspot.com/images/donation-page/image_7.jpeg',
    'https://cdn.dotyourspot.com/images/donation-page/image_8.jpeg',
  ];

  const handleSelect = (index: number) => {
    if (ref.current) {
      ref.current.scrollTo({
        top: 0,
        left: 310 * index,
        behavior: 'smooth',
      });
    }
  };

  const handleButton = (step: number) => {
    if (selectedImage + step < 0) setSelectedImage(0);
    else if (selectedImage + step >= images.length) setSelectedImage(images.length - 1);
    else setSelectedImage((prevState) => prevState + step);
  };

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language, i18n]);

  useEffect(() => {
    handleSelect(selectedImage);
  }, [selectedImage]);

  return (
    <div className="app">
      <div className={'languageWrapper'} onClick={() => setDropdown((prevState) => !prevState)}>
        <div className={'languageHeader'}>
          <span>{language === 'hr' ? 'HR' : 'ENG'}</span>
          <img
            className={'flag'}
            src={
              language === 'hr'
                ? 'https://hatscripts.github.io/circle-flags/flags/hr.svg'
                : 'https://hatscripts.github.io/circle-flags/flags/gb.svg'
            }
            alt={'country-flag'}
          />
        </div>
        <img src={'/static/chevron.svg'} alt={'chevron'} />
        <div className={`languageDropdown ${dropdown && 'languageDropdownOpen'}`}>
          <div className={'languageOption'} onClick={() => setLanguage('en')}>
            ENG
          </div>
          <div className={'languageOption'} onClick={() => setLanguage('hr')}>
            HR
          </div>
        </div>
      </div>
      <img className={'shape1'} src="/static/1.png" alt={'shape'} />
      <img className={'shape3'} src="/static/2.png" alt={'shape'} />
      <h1 className="title">
        {t('mainTitle1')}
        <br />
        {t('mainTitle2')}
      </h1>
      <h2 className="title-name">{t('name')}</h2>
      <img className={'first-image'} src={'https://cdn.dotyourspot.com/images/donation-page/hero.jpg'} alt="katarina" />
      <div className="about-me">
        <div className="about-me-image-wrapper">
          <img
            src={'https://cdn.dotyourspot.com/images/donation-page/about-me.jpeg'}
            className="about-me-image"
            alt="katarina"
          />
        </div>
        <div className="about-me-div">
          <img className={'shape2'} src="/static/3.png" alt={'shape'} />
          <h2 className="subtitle">{t('about.title')}</h2>
          <p className="about-me-text">
            <span style={{ display: 'block' }}>{t('about.text1')}</span>
            {t('about.text2')}
          </p>
          <p className="about-me-text">{t('about.text3')}</p>
          <p className="about-me-text">
            <span style={{ display: 'block' }}>{t('about.text4')}</span>
            {t('about.text5')}
          </p>
          <p className="about-me-text">{t('about.text6')}</p>
        </div>
      </div>
      <div className="donation-section">
        <h2 className="subtitle">{t('donation.title')}</h2>
        <div className="donation-div">
          <div className="donation-info">
            <div className="iban-info">
              <div className="donation-info">
                <p className="m-0">{t('donation.iban')}: HR9824070001500023652</p>
                <div className={'donation-iban-wrapper'}>
                  <img
                    className={'donation-iban'}
                    onClick={handleCopyIban}
                    src={copySvg}
                    width={42}
                    height={42}
                    alt="copy"
                  />
                  <div className={`copyWrapper ${copied && 'copyWrapperOpen'}`}>Copied!</div>
                </div>
              </div>
              <p>{t('donation.text')}</p>
              <p>{t('donation.text_2')}</p>
              <p>{t('donation.text_3')}</p>
              <p>{t('donation.text_4')}</p>
              <p>{t('donation.text_5')}</p>
              <p>{t('donation.text_6')}</p>
            </div>
          </div>
          <img
            src={'https://cdn.dotyourspot.com/images/donation-page/barcode_2.png'}
            className="barcode-image"
            alt="barcode iban"
          />
        </div>
      </div>
      <div id="wrapper">
        <div id="carousel" ref={ref}>
          {images.map((image: string, index: number) => (
            <img
              onClick={() => handleSelect(index)}
              key={image}
              src={image}
              id={'carousel-image'}
              alt={'carousel'}
              draggable={'false'}
            />
          ))}
        </div>
        <button id="prev" onClick={() => handleButton(-1)}>
          <img src={arrowSvg} alt="arrow" width={19} />
        </button>
        <button id="next" onClick={() => handleButton(1)}>
          <img src={arrowSvg} alt="arrow" width={19} style={{ transform: 'rotate(180deg)' }} />
        </button>
      </div>
    </div>
  );
}

export default App;
